import React from "react";
import './searchFilter.css';

const SearchFilter = ({ getPrompts, selectedFilters }) => {


  const handleSearch = (event) => {
    getPrompts(selectedFilters, event.currentTarget.value);
  }

  return (
    <input
      type="text"
      placeholder="Buscar"
      className="search-input"
      onChange={handleSearch}
    />
  );
}

export default SearchFilter;