import './App.css';
import Chat from './pages/chats/chat';
import Login from './pages/login/login';
import SignUp from './pages/signUp/signup';
import AdminPage from './pages/admin/admin';
import AdminFeedbacks from './pages/admin/adminFeedbacks/adminFeedbacks';
import RepoPage from './pages/repo/repo';
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";

function App() {
  const token = localStorage.getItem('token');

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={token ? <Navigate to="/login" /> : <Navigate to="/login" />} />
        <Route path='/repositories' element={<RepoPage />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path='/adminUsers' element={<AdminPage />} />
        <Route path='/adminFeedbacks' element={<AdminFeedbacks />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;