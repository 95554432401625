import React, { useEffect, useState } from 'react';
import './filterList.css';
import { useMemo } from 'react';
import SearchFilter from "../../components/inputs/searchFilter/searchFilter";

const FilterList = ({ getPrompts, currentFilterTitle }) => {
  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await fetch('http://chat-mitca-b9be4d51a636.herokuapp.com/prompt-entries/tags');
        const data = await response.json();
        setFilters(data);
      } catch (error) {
        console.error('Error obteniendo modelos: ', error);
      }
    };

    fetchFilters();
  }, []);

  const handleFilterClick = (event) => {
    const filter = event.target.innerText;
    const newFilters = selectedFilters.includes(filter) ?
      selectedFilters.filter(selectedFilter => selectedFilter !== filter) : [...selectedFilters, filter];
    setSelectedFilters(newFilters);

    getPrompts(newFilters, currentFilterTitle);

    // If the target has CSS class, we remove it
    if (event.target.classList.contains('active')) {
      event.target.classList.remove('active');
    } else {
      event.target.classList.add('active');
    }
  }

  // We useMemo the selected filters to avoid unnecessary re-renders
  const memoizedFilters = useMemo(() => selectedFilters, [selectedFilters]);

  return (
    <div className='filter-container'>
      <SearchFilter getPrompts={getPrompts} selectedFilters={memoizedFilters} />
      <div className='sort-icon'>
        <img src='assets/img/iconos/iconofiltrohome.png' alt='iconofiltro' width={24} height={24}></img>
      </div>
      <div className="filter-list">
        {filters.map((filter, index) => (
          <div key={index} className={`filter-item ${selectedFilters.includes(filter) ? 'active' : ''}`}
            onClick={handleFilterClick}>
            {filter}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterList;
