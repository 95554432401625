import React, { useState, useEffect } from "react";
import './repo.css';
import NavBar from "../../components/navBar/navBar";
import RepoModal from "../../components/repoModal/repoModal";
import FilterList from "../../components/filterList/filterList";
import PromptCard from "../../components/promptCard/promptCard";
import axiosInstance from "../../axiosInstance";
import PromptModal from "../../components/promptModal/promptModal";
import { debounce } from 'lodash';

const RepoPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [prompts, setPrompts] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [currentFilterTitle, setCurrentFilterTitle] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPrompt(null);
  };

  const openPromptModal = () => {
    setIsPromptModalOpen(true);
  };

  const closePromptModal = () => {
    setIsPromptModalOpen(false);
    setSelectedPrompt(null);
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const getPrompts = debounce(async (selectedFilters = [], title = '') => {
    try {
      setCurrentFilterTitle(title);
      const promptRequest = JSON.stringify({
        'title': title,
        'tags': selectedFilters
      });
      const response = await axiosInstance.post('/prompt-entries/getPaginated', promptRequest);
      const data = await response.data;
      setPrompts(data);
    } catch (error) {
      console.error('Error obteniendo modelos: ', error);
    }
  }, 300);

  useEffect(() => {
    getPrompts();
  }, []);

  const openPrompt = (prompt) => {
    setSelectedPrompt(prompt);
    openPromptModal();
  };

  const deletePromptAndUpdateList = (deletedId) => {
    setPrompts(prompts.filter(prompt => prompt._id !== deletedId));
  };

  return (
    <div>
      <NavBar showLinks={true} />
      <div className="repo-filter">
        <FilterList getPrompts={getPrompts} currentFilterTitle={currentFilterTitle} />
      </div>
      <div className="repo-container">
        <div className="cards-flex-container">
          {prompts.map((prompt, index) => (
            <PromptCard key={index} prompt={prompt} onClick={() => openPrompt(prompt)} onDelete={deletePromptAndUpdateList} />
          ))}
        </div>
      </div>
      <button className="repo-button" onClick={openModal}>Crear Prompt</button>
      <button className={`open-filters ${isFilterOpen ? 'hidden' : ''}`} onClick={toggleFilter}>
        {isFilterOpen ? 'Cerrar Filtros' : 'Abrir Filtros'}
      </button>
      {isFilterOpen && (
        <div className="filter-popup">
          <div className="filter-popup-content">
            <button className="close-filter-popup" onClick={toggleFilter}>X</button>
            <FilterList getPrompts={getPrompts} currentFilterTitle={currentFilterTitle} />
          </div>
        </div>
      )}
      {isModalOpen && <RepoModal onClose={closeModal} prompt={selectedPrompt} />}
      {isPromptModalOpen && <PromptModal onClose={closePromptModal} prompt={selectedPrompt} />}
    </div>
  );
}

export default RepoPage;
