import React from "react";
import './input.css';

const Inputs = ({inputName, inputValue, inputType, onChange}) => {
  return (
    <div className="form">
      <input className="input" placeholder={inputName} required="" type={inputType} value={inputValue} onChange={onChange} />
      <span className="input-border"></span>
    </div>
  )
}

export default Inputs