import React, { useState } from 'react';
import './promptCard.css';
import axiosInstance from "../../axiosInstance";

const PromptCard = ({ prompt, onClick, onDelete }) => {
  const [iconRoute, setIconRoute] = useState('/icono_user.png');

  const user = JSON.parse(localStorage.getItem('user'));
  const currentUserId = user ? user.id : null;

  const onMouseEnter = () => {
    setIconRoute('/icono_user_white.png');
  };

  const onMouseLeave = () => {
    setIconRoute('/icono_user.png');
  };

  const deletePrompt = async (e) => {
    e.stopPropagation();
    try {
      if (prompt && prompt._id) {
        await axiosInstance.delete(`/prompt-entries/delete/${prompt._id}`);
        onDelete(prompt._id); 
      } else {
        console.error('Prompt ID is undefined');
      }
    } catch (error) {
      console.error('Error deleting prompt: ', error);
    }
  };

  return (
    <div className="prompt-card" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick}>
      <img src={iconRoute} alt="Icon" className="prompt-icon" />
      <h3>{prompt.title}</h3>
      <p>{prompt.resource}</p>
      <p>{prompt.description}</p>
      {currentUserId === prompt.user_id && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width={18} height={18} fill="red" onClick={deletePrompt}>
          <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/>
        </svg>
      )}
    </div>
  );
};

export default PromptCard;
