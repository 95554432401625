import React from 'react';
import './reportModal.css';

function ReportModal({ isOpen, onClose, onReport, userMessage, onChange }) {

  const handleModalClick = (e) => {
    if (e.target.classList.contains('report-modal')) {
      onClose();
    }
  };

  return (
    isOpen && (
      <div className="report-modal" onClick={handleModalClick}>
        <div className="report-content">
          <span className="close" onClick={onClose}>&times;</span>
          <h2>Report Feedback</h2>
          <form onSubmit={onReport}>
            <label>
              User Message:
              <input type="text" value={userMessage} onChange={onChange} />
            </label>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    )
  );
}

export default ReportModal;