import axios from 'axios';

// Singleton instance of axios
const instance = axios.create({
    baseURL: 'http://chat-mitca-b9be4d51a636.herokuapp.com/',
    headers: {
        'Content-Type': 'application/json',
    }
});

// We add a request interceptor to handle expiration tokens
instance.interceptors.request.use(async (config) => {
    let token = localStorage.getItem('token');
    const tokenExpiration = localStorage.getItem('tokenExpiration');

    const tokenExpirationMs = new Date(tokenExpiration).getTime();
    // If the token has 10 minutes left to expire, we refresh it
    if ((tokenExpirationMs - Date.now()) < (10 * 60 * 1000)) {
        const response = await axios.post(
            'http://chat-mitca-b9be4d51a636.herokuapp.com/users/refresh-token',
            { token: token }
        );

        // We store the new token and expiration date
        // The user does not change, so we do not need to update it
        token = response.data.access_token;

        localStorage.setItem('token', token);
        localStorage.setItem('tokenExpiration', response.data.expiration);
    }

    // We always set the Authorization header
    config.headers.Authorization = `Bearer ${token}`;

    return config;
}, (error) => {
    return Promise.reject(error);
});

export default instance;