import React, { useEffect, useState } from "react";
import './selectModel.css';
import axiosInstance from "../../axiosInstance";

const SelectModel = ({ selectedModel, onChange }) => {
  const [models, setModels] = useState([]);
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    getModels().then(data => {
      setModels(data);
      if (data.length > 0) {
        onChange({ target: { value: data[0].toLowerCase() } }); 
      }
    });
    getAgents();
  }, []);

  const getModels = async () => {
    try {
      const response = await fetch('http://chat-mitca-b9be4d51a636.herokuapp.com/models');
      return await response.json();
    } catch (error) {
      console.error('Error obteniendo modelos: ', error);
      return [];
    }
  };

  const getAgents = async () => {
    try {
      const response = await axiosInstance.get("/agents/current");
      setAgents(response.data); 
    } catch (error) {
      console.error("Error al recuperar agentes:", error);
    }
  };

  return (
    <div className='model-selector-container'>
      <div className='model-selector'>
        <select value={selectedModel} onChange={onChange}>
          <optgroup label="Modelos">
            {models.map((model, index) => (
              <option key={index} value={model.toLowerCase()}>{model}</option>
            ))}
          </optgroup>
          <optgroup label="Asistentes">
            {agents.map((agent, index) => (
              <option key={index} value={agent._id}>{agent.title}</option>
            ))}
          </optgroup>
        </select>
      </div>
    </div>
  )
}

export default SelectModel;
