import React, { useState, useEffect } from "react";
import Header from "../../../components/header/header";
import DataTable from 'react-data-table-component';
import './adminFeedbacks.css';
import axiosInstance from "../../../axiosInstance";

const columns = [
  {
    name: 'Feedback',
    selector: row => row.user_message,
    sortable: true,
  },
  {
    name: 'Prompt de Usuario',
    selector: row => row.problem.user_query,
    sortable: true,
  },
  {
    name: 'Respuesta al Prompt',
    selector: row => row.problem.ai_response,
    sortable: true,
  },
  {
    name: 'Fecha Creación',
    selector: row => row.date,
    sortable: true,
  }
];

const AdminFeedbacks = () => {
  const [feedbacks, setFeedbacks] = useState({});
  const [loading, setLoading] = useState(true);
  const [expandedModel, setExpandedModel] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');

    const fetchFilteredFeedbacks = async (modelName, token) => {
      const response = await axiosInstance.get(`/feedbacks/filtered/${modelName}`);
      return await response.data;
    };

    const fetchFeedbacks = async () => {
      try {
        const gemmaFeedbacks = await fetchFilteredFeedbacks("gemma", token);
        const zephyrFeedbacks = await fetchFilteredFeedbacks("zephyr", token);
        const hermesFeedbacks = await fetchFilteredFeedbacks("hermes", token);

        const feedbacksData = {
          Gemma: gemmaFeedbacks,
          Zephyr: zephyrFeedbacks,
          Hermes: hermesFeedbacks,
        };
        setFeedbacks(feedbacksData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching feedbacks:', error);
      }
    };

    fetchFeedbacks();
  }, []);

  const handleModelClick = (modelName) => {
    setExpandedModel(expandedModel === modelName ? null : modelName);
  };

  const renderTables = () => {
    return Object.keys(feedbacks).map(modelName => (
      <div className="feedback-tables" key={modelName}>
        <h2 onClick={() => handleModelClick(modelName)}>{modelName}</h2>
        {expandedModel === modelName && (
          <DataTable
            columns={columns}
            data={feedbacks[modelName]}
          />
        )}
      </div>
    ));
  };

  return (
    <div>
      <Header />
      {loading ? <p>Loading...</p> : renderTables()}
    </div>
  );
};

export default AdminFeedbacks;
