import React from "react";
import Header from "../../components/header/header";
import AdminUser from "./adminUsers/adminUsers";

const AdminPage = () => {
  return (
    <div>
      <Header />
      <AdminUser />
    </div>
  )
}

export default AdminPage;