import React from "react";
import './primaryButton.css';

const PrimaryButton = ({buttonText, buttonFunction}) => {
  return (
    
        <button className="primary-button" onClick={buttonFunction}>{buttonText}</button>
    
  )
}

export default PrimaryButton