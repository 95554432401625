import React, { useEffect, useState } from "react";
import './promptModel.css';

const PromptModel = ({ selectedModel, onChange }) => {

  const [models, setModels] = useState([]);

  useEffect(() => {
    getModels().then(data => {
      setModels(data)
    });
  }, []);

  const getModels = async () => {
    try {
      const response = await fetch('http://chat-mitca-b9be4d51a636.herokuapp.com/models/system');
      return await response.json();
    }
    catch (error) {
      console.error('Error obteniendo modelos: ', error);
      return [];
    }
  };

  return (
    <div className='system-selector'>
      <select value={selectedModel} onChange={onChange}>
        {models.map((model, index) => (
          <option key={index} value={model.toLowerCase()}>{model}</option>
        ))}
      </select>
    </div>
  )
}

export default PromptModel;