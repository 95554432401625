import React from 'react';
import './input.css';

function ChatInput({ placeHolder, messageValue, onChange, onButtonClick }) {
  return (
    <div className="chat-input-container">
      <textarea 
      value={messageValue} onChange={onChange} placeholder={placeHolder} className="chat-input" rows="1" />
      <button className="send-button">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="send-icon" onClick={onButtonClick}>
          <line x1="22" y1="2" x2="11" y2="13"></line>
          <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
        </svg>
      </button>
    </div>
  );
}

export default ChatInput;
