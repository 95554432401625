import "./promptModal.css";
import React from 'react';

const PromptModal = ({ onClose, prompt }) => {
  const openFile = (url) => {
    window.open(url, '_blank');
  }

  return (
    <div className="prompt-modal">
      <h2 className="prompt-title">{prompt?.title}</h2> 
      <p className="prompt-description">{prompt?.description}</p> 
      {
        prompt?.isFile 
          ? <button onClick={() => openFile(`http://chat-mitca-b9be4d51a636.herokuapp.com/prompt-entries/pdf/${prompt?.resource}`)} className="file-button">Ver archivo</button> 
          : <p className="prompt-resource">{prompt?.resource}</p> 
      }
      <button onClick={onClose} className="close-button">Cerrar</button>
    </div>
  );
};

export default PromptModal;