import React, { useState, useEffect } from "react";
import './chatHistory.css';

const ChatHistory = ({ conversations, onSelectConversation, selectedConversationId, onDeleteConversation }) => {
  const [showOptions, setShowOptions] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showOptions && !event.target.closest(".options-panel") && !event.target.closest(".settings-icon")) {
        setShowOptions(null);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showOptions]);

  const handleSettingsClick = (event, id) => {
    event.stopPropagation();
    setShowOptions(id);
  };

  const handleDeleteConversation = async (conversationId) => {
    try {
      await onDeleteConversation(conversationId);
      setShowOptions(null); 
    } catch (error) {
      console.error('Error deleting conversation:', error.message);
    }
  };

  return (
    <div className="chat-history">
      <h2>Chat History</h2>
      <ul>
        {conversations.map((conversation, index) => (
          <li key={conversation.id}>
            <div className={`conversation-item ${conversation.id === selectedConversationId ? 'selected' : ''}`}
              onClick={() => {
                if (conversation.id !== selectedConversationId) {
                  onSelectConversation(conversation.id, index);
                }
              }}>
              <p>{conversation.details}</p>
              <div className="settings-icon"
                onClick={(event) => handleSettingsClick(event, conversation.id)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path fill="currentColor" d="M12 6c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                </svg>
              </div>
              {showOptions === conversation.id && (
                <div className="options-panel">
                  <div onClick={() => handleDeleteConversation(conversation.id)}>Eliminar</div>
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChatHistory;