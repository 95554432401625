import React, { useEffect } from "react";
import Modal from "react-modal";
import UserPreferences from "../userPreferences/userPreferences";
import './userModal.css';

const UserModal = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    openModal(); 
  }, []); 

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <Modal 
        isOpen={modalIsOpen} 
        onRequestClose={closeModal} 
        overlayClassName="overlay" 
        className="modal"
        shouldCloseOnOverlayClick={true} 
        shouldCloseOnEsc={true}>
        <UserPreferences closeModal={closeModal} />
      </Modal>
    </div>
  );
}

export default UserModal;
