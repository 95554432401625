import React, { useState, useEffect } from 'react';
import './sidebar.css';
import { useNavigate } from 'react-router-dom';
import ChatHistory from '../chatHistory/chatHistory';
import UserModal from '../userModal/userModal';
import SystemInput from '../../components/systemInput/systemInput';
import axiosInstance from "../../axiosInstance";

const SideBar = ({ storedUser = JSON.parse(localStorage.getItem('user')), onSelectConversation }) => {
  const [email, setEmail] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [isSystemInputOpen, setIsSystemInputOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setEmail(storedUser.email);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };
    fetchUser();
  }, [storedUser.email]);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await axiosInstance.get('/permissions/current');
        if (response.status === 200) {
          setPermissions(response.data);
        }
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    };
    fetchPermissions();
  }, []);

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  };

  const userSettings = () => {
    setShowSettings(!showSettings);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
    if (showSettings) {
      setShowSettings(false);
    }
  };

  const fetchConversations = async () => {
    try {
      const response = await axiosInstance.get('/users/conversations');
      if (response.status !== 200) {
        throw new Error('Failed to fetch conversations');
      }
      const data = await response.data;
      const conversationsArray = Object.entries(data).map(([id, details]) => ({
        id,
        details
      }));
      setConversations(conversationsArray);
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  useEffect(() => {
    fetchConversations();
  }, []);

  const handleDeleteConversation = async (conversationId) => {
    try {
      await axiosInstance.delete(`/users/conversations/${conversationId}`);
      console.log('Conversation deleted:', conversationId);
      fetchConversations();
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };

  const toggleSystemInput = () => {
    setIsSystemInputOpen(!isSystemInputOpen);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div className='sidebar-toggle' onClick={toggleSidebar}>
        <svg className='toggle-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="black">
          <path d={isSidebarOpen ? "M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" : "M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"} />
        </svg>
      </div>
      <div className='newchat-container'>
        <a className='newchat' href='/chat'>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24" className="newchat-icon">
            <path d="M15.673 3.913a3.121 3.121 0 1 1 4.414 4.414l-5.937 5.937a5 5 0 0 1-2.828 1.415l-2.18.31a1 1 0 0 1-1.132-1.13l.311-2.18A5 5 0 0 1 9.736 9.85zm3 1.414a1.12 1.12 0 0 0-1.586 0l-5.937 5.937a3 3 0 0 0-.849 1.697l-.123.86.86-.122a3 3 0 0 0 1.698-.849l5.937-5.937a1.12 1.12 0 0 0 0-1.586M11 4A1 1 0 0 1 10 5c-.998 0-1.702.008-2.253.06-.54.052-.862.141-1.109.267a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216C5.001 8.471 5 9.264 5 10.4v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h3.2c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.126-.247.215-.569.266-1.108.053-.552.06-1.256.06-2.255a1 1 0 1 1 2 .002c0 .978-.006 1.78-.069 2.442-.064.673-.192 1.27-.475 1.827a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C15.6 21 14.727 21 13.643 21h-3.286c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.233-.487-1.961C3 15.6 3 14.727 3 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.729.185-1.369.487-1.961A5 5 0 0 1 5.73 3.545c.556-.284 1.154-.411 1.827-.475C8.22 3.007 9.021 3 10 3A1 1 0 0 1 11 4"></path>
          </svg>
        </a>
      </div>
      <div className={`sidebar-container ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
        {isSidebarOpen && (
          <>
            <div className='sidebar-history'>
              <ChatHistory conversations={conversations} onSelectConversation={onSelectConversation} onDeleteConversation={handleDeleteConversation} />
            </div>
            <div className='settings-container'>
              <div className='settings' onClick={togglePopup}>
                <div className='sidebar-username'>{storedUser.username}</div>
              </div>
              {showPopup && (
                <div className='show-popup'>
                  <a onClick={userSettings}>Ajustes</a>
                  <a onClick={logout}>Cerrar Sesión</a>
                  {permissions.some(permission => permission.name === 'admin') && (
                    <a href='/adminUsers'>Administración</a>
                  )}
                </div>
              )}
              {showSettings && (
                <UserModal />
              )}
            </div>
            <button onClick={toggleSystemInput} className='agents-button'>Crear Asistente</button>
            {isSystemInputOpen && <SystemInput />}
            <button className='repos-button'><a href='/repositories'>Repositorios</a></button>
          </>
        )}
      </div>
    </>
  );
};

export default SideBar;