import React, { useState, useEffect } from 'react';
import './login.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavBar from '../../components/navBar/navBar';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    setError('');

    try {
      const newUser = new FormData();
      newUser.append('username', email);
      newUser.append('password', password);
      const response = await axios.post('http://chat-mitca-b9be4d51a636.herokuapp.com/users/login', newUser);
      const access_token = response.data.access_token;
      const user = response.data.user;
      const tokenExpiration = response.data.expiration;

      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', access_token);
      localStorage.setItem('tokenExpiration', tokenExpiration);

      navigate('/');
      console.log(user);
    } catch (error) {
      console.error('Error al hacer la solicitud POST:', error);
      setError('Nombre de usuario o contraseña incorrectos.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/repositories');
    }
  }, [navigate]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div>
      <NavBar showLinks={false}/>
      <div className="login-container">
        <div className="login-box">
          <h2>Bienvenido</h2>
          <div className="icon">
            <img src="assets/img/iconos/iconouserlogin.png" alt="icono" width="50" />
          </div>
          <form>
            <div className="textbox">
              <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Correo electrónico' />
            </div>
            <div className="textbox">
              <input type={passwordVisible ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Contraseña' />
              <span className="eye" onClick={togglePasswordVisibility}>
                {passwordVisible ? (
                  <img src='assets/img/iconos/iconoojologin.png' alt='eye-visible'></img>
                ) : (
                  <img src='assets/img/iconos/iconoojologin.png' alt='eye-not-visible'></img>
                )}
              </span>
              {error && <p className='error-message'>{error}</p>}
            </div>
            <button className="login-btn" type='submit' onClick={handleSubmit} disabled={loading}>ENTRAR</button>
          </form>
          <p>¿No tienes cuenta?<a href="/signUp"> Crear cuenta</a></p>
        </div>
      </div>
    </div>
  );
}

export default Login;
