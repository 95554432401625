import { React, useState } from 'react';
import './signup.css';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../components/navBar/navBar';

function SignUp() {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleSignup = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('http://chat-mitca-b9be4d51a636.herokuapp.com/users/new', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email, username, password }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail);
      }
      const responseData = await response.json(); 
      console.log(responseData);
      navigate('/login');
    } catch (error) {
      console.error('Error al hacer la solicitud POST:', error);
      console.error('Error en la solicitud POST:', error);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div>
      <NavBar showLinks={false} />
      <div className='signup-container'>
        <div className='signup-box'>
          <h2>Crear Cuenta</h2>
          <div className="signup-icon">
            <img src="assets/img/iconos/iconouserlogin.png" alt="icono" width="50" />
          </div>
          <form>
            <div className='signup-textbox'>
              <input type='text' value={username} onChange={(e) => setUsername(e.target.value)} placeholder='Nombre usuario' ></input>
              <input type='text' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Correo electrónico' ></input>
            </div>
            <div className='signup-textbox'>
              <input type={passwordVisible ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Contraseña' ></input>
              <span className="signup-eye" onClick={togglePasswordVisibility}>
                {passwordVisible ? (
                  <img src='assets/img/iconos/iconoojologin.png' alt='eye-visible'></img>
                ) : (
                  <img src='assets/img/iconos/iconoojologin.png' alt='eye-not-visible'></img>
                )}
              </span>
            </div>
            <button className="signup-btn" type='submit' onClick={handleSignup}>ENTRAR</button>
          </form>
          <p>¿Ya registrado?<a href="/login"> Login</a></p>
        </div>
      </div>
    </div >
  )
}

export default SignUp