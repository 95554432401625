import React from "react";
import './header.css';

const Header = () => {
  return (
    <div className="header-container">
      <li>
        <a href="/adminUsers">Usuarios</a>
        <a href="/adminFeedBacks">Feedbacks</a>
      </li>
    </div>
  )
}

export default Header;