import React, { useState } from "react";
import "./systemInput.css";
import PromptButton from "../buttons/promptButton/promptButton";
import PromptModel from "../promptModel/promptModel";
import axiosInstance from "../../axiosInstance";

const SystemInput = () => {
  const [title, setTitle] = useState("");
  const [systemPrompt, setSystemPrompt] = useState("");
  const [baseModel, setBaseModel] = useState("");

  const handleAccept = async () => {
    const agentData = {
      title: title,
      system_prompt: systemPrompt,
      base_model: baseModel 
    };

    try {
      const response = await axiosInstance.post("/agents/new", JSON.stringify(agentData));
      if (response.status === 200) {
        console.log("Agent created successfully");
      } else {
        console.error("Error creating agent:", response.statusText);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  return (
    <dialog className="system-container" open>
      <div className="content">
        <h1>Model</h1>
        <PromptModel selectedModel={baseModel} onChange={(e) => setBaseModel(e.target.value)} />
        <input 
          placeholder="Model name" 
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <p>System Prompt</p>
        <textarea
          className="system-input"
          placeholder="E.g.: You are a math teacher"
          value={systemPrompt}
          onChange={(e) => setSystemPrompt(e.target.value)}
        />
        <PromptButton onClick={handleAccept} />
      </div>
    </dialog>
  );
};

export default SystemInput;
