import React, { useState } from 'react';
import './userPreferences.css';
import PrimaryButton from "../buttons/primaryButton/primaryButton";
import Inputs from '../inputs/userPreferencesInputs/input';
import axiosInstance from "../../axiosInstance";

const UserPreferences = ({ storedUser = JSON.parse(localStorage.getItem('user')), closeModal }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState(storedUser.email || '');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleUpdate = async () => {
    try {
      const id = storedUser.id;
      const response = await axiosInstance.put(`/users/update`, JSON.stringify({ id, username, email }));
      if (response.status !== 200) {
        const errorData = await response.data;
        throw new Error(JSON.stringify(errorData));
      }
      const responseData = await response.data;
      localStorage.setItem('user', JSON.stringify(responseData));
      console.log(responseData);
    } catch (error) {
      console.error('Error al hacer la solicitud PUT para actualizar el usuario:', JSON.stringify(error));
    }
  }

  const handlePasswordUpdate = async () => {
    try {
      const id = storedUser.id;
      const response = await axiosInstance(`/users/updatePassword?user_id=${id}&old_password=${password}&new_password=${newPassword}`);
      if (response.status !== 200) {
        const errorData = await response.data;
        throw new Error(errorData.detail);
      }
      const responseData = await response.data;
      console.log(responseData);
      setPassword('');
      setNewPassword('');
    } catch (error) {
      console.error('Error al hacer la solicitud PUT para actualizar la contraseña:', error);
    }
  }

  return (
    <div className='form-container'>
      <div className='form-container-header'>
        <h2>Settings</h2>
        <svg className='close-icon' onClick={closeModal} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
          <path fill="white" d="M18.3 5.71a1 1 0 00-1.42-1.42L12 9.58 7.12 4.7a1 1 0 00-1.42 1.42L10.58 12l-4.88 4.88a1 1 0 101.42 1.42L12 14.42l4.88 4.88a1 1 0 001.42-1.42L13.42 12l4.88-4.88z"/>
        </svg>
      </div>
      <div className='name-email-reset'>
        <Inputs inputName={"Username"} inputValue={username} inputType={"text"} onChange={(e) => setUsername(e.target.value)}/>
      </div>
      <PrimaryButton buttonText={"Update"} buttonFunction={handleUpdate} />
      <div className='password-reset'>
        <Inputs inputName={"Actual Password"} inputValue={password} inputType={"password"} onChange={(e) => setPassword(e.target.value)}/>
        <Inputs inputName={"New Password"} inputValue={newPassword} inputType={"password"} onChange={(e) => setNewPassword(e.target.value)}/>
      </div>
      <PrimaryButton buttonText={"Update Password"} buttonFunction={handlePasswordUpdate} />
    </div>
  );
}

export default UserPreferences;
