import React, { useState } from "react";
import './navBar.css';

const NavBar = ({ showLinks }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('isFilterOpen');
    window.location.href = '/';
  }

  const token = localStorage.getItem('token');

  return (
    <header className="navbar">
      <div className="navbar-logo">
        <h1>CHATprovi</h1>
      </div>
      <div className={`navbar-nav ${isOpen ? 'open' : ''}`}>
        {showLinks && (
          <ul>
            <li>
              <a href="/chat">
                <img src="assets/img/iconos/iconochatlogin.png" alt="iconochat" width={24} height={24} />
                Chat
              </a>
            </li>
            <li>
              <a onClick={togglePopup}>
                <div className="icon-container">
                  <img src="assets/img/iconos/iconouserhome.png" alt="iconouser" width={24} height={24} />
                  Cuenta
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#A101F9" width={15} height={20}>
                    <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                  </svg>
                </div>
              </a>
              {isPopupOpen && (
                <div className="popup">
                  {token ? (
                    <>
                      <a href="/adminUsers">Administración</a>
                      <a onClick={logOut}>Cerrar Sesión</a>
                    </>
                  ) : (
                    <a href="/login">Iniciar Sesión</a>
                  )}
                </div>
              )}
            </li>
          </ul>
        )}
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <div className="hamburger-icon">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      {isOpen && (
        <div className="hamburger-menu">
          {token ? (
            <>
              <a href="/adminUsers">Administración</a>
              <a onClick={logOut}>Cerrar Sesión</a>
            </>
          ) : (
            <a href="/login">Iniciar Sesión</a>
          )}
        </div>
      )}
    </header>
  );
}

export default NavBar;