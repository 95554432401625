import React, { useState, useEffect } from 'react';
import './chat.css';
import SideBar from '../../components/sidebar/sidebar';
import ChatInput from '../../components/inputs/chatInput/input';
import SelectModel from '../../components/selectModel/selectModel';
import ReportModal from '../../components/reportModal/reportModal';
import axiosInstance from '../../axiosInstance';

import ReactMarkdown from 'react-markdown'

function Chat() {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [socket, setSocket] = useState(null);
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [editingMessageIndex, setEditingMessageIndex] = useState(null);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [reportMessageIndex, setReportMessageIndex] = useState(null);
  const [reportMessage, setReportMessage] = useState('');

  const handleSelectConversation = (conversationId) => {
    try {
      setSelectedConversationId(conversationId);
      setMessages([]);
    } catch (error) {
      console.error('Error connecting to WebSocket:', error);
    }
  };

  const sendMessage = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      if (newMessage.trim() !== '') {
        let messageToSend;
        if (selectedConversationId) {
          const messageType = "new";
          const messageIndex = messages.length;
          messageToSend = JSON.stringify({ type: messageType, index: messageIndex, content: newMessage });
        } else {
          const messageIndex = 0;
          messageToSend = JSON.stringify({ type: "new", index: messageIndex, content: newMessage });
        }
        socket.send(messageToSend);
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setNewMessage('');
      }
    }
  };

  useEffect(() => {
    let socket;
    const handleIncomingMessage = (event) => {
      try {
        if (selectedConversationId) {
          const newMessages = JSON.parse(event.data);
          newMessages.forEach(newMessage => {
            const formattedMessage = Array.isArray(newMessage) ? newMessage[1] : newMessage;
            setMessages(prevMessages => {
              const newMessageToAdd = formattedMessage;
              if (!prevMessages.some(message => message.trim() === newMessageToAdd.trim())) {
                return [...prevMessages, newMessageToAdd];
              }
              return prevMessages;
            });
          });
        }
        else {
          const formattedMessage = event.data;
          setMessages(prevMessages => {
            const newMessageToAdd = formattedMessage;
            if (!prevMessages.some(message => message.trim() === newMessageToAdd.trim())) {
              return [...prevMessages, newMessageToAdd];
            }
            return prevMessages;
          });
        }
      } catch (error) {
        setMessages(prevMessages => {
          const newMessageToAdd = event.data;
          if (!prevMessages.some(message => message.trim() === newMessageToAdd.trim())) {
            return [...prevMessages, newMessageToAdd];
          } else {
            console.error('Error parsing incoming message:', error);
          }
          return prevMessages;
        });
      }
    };

    if (selectedConversationId) {
      socket = new WebSocket(`ws://chat-mitca-b9be4d51a636.herokuapp.com/chat?conversation_id=${selectedConversationId}`);
    } else {
      socket = new WebSocket(`ws://chat-mitca-b9be4d51a636.herokuapp.com/chat?model=${selectedModel}`);
    }

    socket.onopen = () => {
      socket.send(localStorage.getItem('token'));
    };

    socket.onmessage = handleIncomingMessage;

    socket.onclose = () => {
      console.log('WebSocket disconnected');
    };

    setSocket(socket);
    
  }, [selectedModel, selectedConversationId]);

  const handleEditMessage = (index, message) => {
    setEditingMessageIndex(index);
    setNewMessage(message);
  };

  const handleSendEditedMessage = () => {
    const newMessages = [...messages];
    newMessages[editingMessageIndex] = newMessage;
    setMessages(newMessages);
    setEditingMessageIndex(null);
    setNewMessage('');
    if (selectedConversationId) {
      const messageType = "edit";
      const messageToSend = JSON.stringify({ type: messageType, index: editingMessageIndex, content: newMessage });
      socket.send(messageToSend);
    }
    const remainingMessages = newMessages.slice(0, editingMessageIndex + 1);
    setMessages(remainingMessages);
  };

  const editMessage = (index) => {
    if (selectedConversationId) {
      return index % 2 === 0;
    } else {
      return index % 2 === 1;
    }
  };

  const isUserMessage = (index) => {
    if (selectedConversationId) {
      return index % 2 === 0;
    } else {
      return index % 2 === 1;
    }
  };

  const handleReportFeedback = (index) => {
    const userData = JSON.parse(localStorage.getItem('user'));
    const userId = userData.id;

    const currentDate = new Date().toISOString();

    const userQuery = messages[index - 1];
    const aiResponse = messages[index];
    console.log('User Message:', reportMessage);
    const feedbackData = {
      user_id: userId,
      date: currentDate,
      problem: {
        conversation_id: selectedConversationId,
        user_query: userQuery,
        ai_response: aiResponse,
        ai_model_name: selectedModel
      },
      user_message: reportMessage
    };
    axiosInstance.post('/feedbacks/new',  JSON.stringify(feedbackData))
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to send feedback');
        }
        console.log('Feedback sent successfully');
      })
      .catch(error => {
        console.error('Error sending feedback:', error);
      });
    setIsReportModalOpen(false);
  };

  const toggleReportModal = (index) => {
    setIsReportModalOpen(!isReportModalOpen);
    setReportMessageIndex(index);
  };

  return (
    <div className="chat-layout">
      <SideBar onSelectConversation={handleSelectConversation} />
      <div className='chat-container'>
        <div className='message-container'>
          {messages.map((message, index) => (
            <div key={index} className={isUserMessage(index) ? 'user-message message user' : 'server-message message server'}>
              <div><ReactMarkdown>{message}</ReactMarkdown></div>
              {editMessage(index) && (
                <div className="edit-icon" onClick={() => handleEditMessage(index, message)}>
                  <svg className='edit-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="15" height="15">
                    <path fill="black" d="M18.293,1.707c-0.391-0.391-1.023-0.391-1.414,0l-14,14C2.109,15.902,2,16.049,2,16.207v2.586 c0,0.553,0.447,1,1,1h2.586c0.158,0,0.305-0.109,0.414-0.293l14-14C18.684,2.73,18.684,2.097,18.293,1.707z M3,17.414V16H4.414 L17.414,3H16L3,16.414V17.414z M14.707,5.707l-0.707,0.707l-1-1L13.707,4L15,5.293L14.707,5.707z M13.293,7.121L6,14.414V15h0.586 l7.293-7.293L13.293,7.121z" />
                  </svg>
                </div>
              )}
              {!isUserMessage(index) && (
                <div className="server-message message server" >
                  <svg height="20" viewBox="0 0 48 48" width="20" xmlns="http://www.w3.org/2000/svg" fill='black' onClick={() => toggleReportModal(index)}>
                    <path d="M31.46 6h-14.92l-10.54 10.54v14.91l10.54 10.55h14.91l10.55-10.54v-14.92l-10.54-10.54zm-7.46 28.6c-1.43 0-2.6-1.16-2.6-2.6 0-1.43 1.17-2.6 2.6-2.6 1.43 0 2.6 1.16 2.6 2.6 0 1.44-1.17 2.6-2.6 2.6zm2-8.6h-4v-12h4v12z" />
                    <path d="M0 0h48v48h-48z" fill="none" />
                  </svg>
                </div>
              )}
            </div>
          ))}
        </div>
        <ChatInput
          placeHolder={"Enviar mensaje..."}
          messageValue={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onButtonClick={editingMessageIndex !== null ? handleSendEditedMessage : sendMessage} />
      </div>
      <SelectModel selectedModel={selectedModel} onChange={(e) => setSelectedModel(e.target.value)} />
      <ReportModal
        isOpen={isReportModalOpen}
        onClose={() => setIsReportModalOpen(false)}
        onReport={() => handleReportFeedback(reportMessageIndex)}
        onChange={(e) => setReportMessage(e.target.value)}
        userMessage={reportMessage}
      />
    </div>
  );
}

export default Chat;