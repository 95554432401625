import React, { useState, useRef, useEffect } from 'react';
import './repoModal.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import axiosInstance from "../../axiosInstance";

const RepoModal = ({ onClose }) => {
  const [choice, setChoice] = useState('write');
  const [selectedSize, setSelectedSize] = useState('L');
  const fileInput = useRef(null);
  const [fileBase64, setFileBase64] = useState(null);
  const [fileName, setFileName] = useState('');
  const [title, setTitle] = useState('');
  const [textContent, setTextContent] = useState('');
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [description, setDescription] = useState('');

  const animatedComponents = makeAnimated();

  const handleChoice = (selectedChoice) => {
    setChoice(selectedChoice);
    setTitle('');
    setTextContent('');
    setFileBase64(null);
    setFileName('');
    setSelectedTags([]);
    setDescription('');
    setSelectedSize('');
  };

  const getFileNameWithoutExtension = (name) => {
    return name.substring(0, name.lastIndexOf('.')) || name;
  };

  const convertToBase64 = (files) => {
    Array.from(files).forEach(file => {
      const nameWithoutExtension = getFileNameWithoutExtension(file.name);
      setFileName(nameWithoutExtension);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const base64 = reader.result.split(',')[1];
        setFileBase64(base64);
      };
    });
  };

  const getTags = async () => {
    try {
      const response = await fetch('http://chat-mitca-b9be4d51a636.herokuapp.com/prompt-entries/tags');
      const data = await response.json();
      setTags(data);
    } catch (error) {
      console.error('Error getting tags: ', error);
      return ([]);
    }
  };

  const uploadFile = async () => {
    if (!fileBase64 && choice === 'upload') {
      console.error('No file selected');
      return;
    }

    const resource = choice === 'write' ? textContent : fileBase64;
    const newEntry = JSON.stringify({
      type: selectedSize,
      title: title || fileName,
      isFile: choice === 'upload',
      resource: resource,
      tags: selectedTags,
      description: description
    });

    const response = await axiosInstance.post('/prompt-entries/new', newEntry);
    if (response.status !== 200) {
      console.error('Upload failed');
      return;
    }

    const data = await response.data;
    console.log(data);
    onClose();
  };

  const handleFileChange = (e) => {
    convertToBase64(e.target.files);
  };

  useEffect(() => {
    getTags();
  }, []);

  const options = tags.map((tag) => ({ label: tag, value: tag }));

  const handleTagChange = (selectedOptions) => {
    setSelectedTags(selectedOptions.map(option => option.value));
  };

  return (
    <div className="modal-background">
      <div className="modal-content">
        <svg className='modal-svg-close' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width={24} height={24} fill='black' onClick={onClose}>
          <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
        </svg>
        <div className='svg-choices'>
          {choice !== 'write' && (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={24} height={24} fill='black' onClick={() => handleChoice('write')}>
              <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z" />
            </svg>
          )}
          {choice !== 'upload' && (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width={24} height={24} fill='black' onClick={() => handleChoice('upload')}>
              <path d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
            </svg>
          )}
        </div>
        {choice === 'write' && (
          <div>
            <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
            <textarea placeholder="Escriba el prompt" value={textContent} onChange={(e) => setTextContent(e.target.value)}></textarea>
            <textarea placeholder="Descripción del prompt" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
            <Select className='select-tag' classNamePrefix="react-select" closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={options}
              onChange={handleTagChange}>
            </Select>
            <button onClick={uploadFile} className="upload-pdf-button">Submit</button>
          </div>
        )}
        {choice === 'upload' && (
          <div>
            <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
            <input type="file" ref={fileInput} onChange={handleFileChange} />
            <textarea placeholder="Descripción del prompt" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
            <Select className='select-tag' classNamePrefix="react-select" closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={options}
              onChange={handleTagChange}>
            </Select>
            <button onClick={uploadFile} className="upload-pdf-button">Subir Prompt</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RepoModal;