import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axiosInstance";
import "./adminUser.css";

const AdminUser = () => {
  const [users, setUsers] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    permissions: 'admin',
    disabled: 'false'
  });

  useEffect(() => {
    const fetchUsersAndPermissions = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axiosInstance.get("/users");
        setUsers(response.data);

        const permissionsResponse = await axiosInstance.get("/permissions");
        setPermissions(permissionsResponse.data);
      } catch (error) {
        console.error("Error al obtener usuarios o permisos:", error);
      }
    };

    fetchUsersAndPermissions();
  }, []);

  const deleteUser = async (userId) => {
    try {
      const token = localStorage.getItem('token');
      await axiosInstance().delete(`/users/delete/${userId}`);
      const updatedUsers = users.filter(user => user.id !== userId);
      setUsers(updatedUsers);
    } catch (error) {
      console.error("Error al eliminar usuario:", error);
    }
  };

  const openModal = (user) => {
    setCurrentUser(user);
    const permissionName = permissions.find(
        permission => permission._id === user.permissions[0]
    ).name;

    setFormData({
      username: user.username,
      email: user.email,
      permissions: permissionName,
      disabled: user.disabled,
    });
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentUser(null);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const handleUpdateUser = async (e) => {
    e.preventDefault();

    try {
      const newPermission = permissions.find(permission => permission.name === formData.permissions);

      const updatedUserData = {
        id: currentUser.id,
        username: formData.username,
        email: formData.email,
        permissions: [newPermission._id],
        disabled: formData.disabled
      };

      await axiosInstance.put(`/users/update`, updatedUserData);

      const updatedUsers = users.map((user) =>
        user.id === currentUser.id ? { ...user, ...updatedUserData } : user
      );
      setUsers(updatedUsers);
      closeModal();
    } catch (error) {
      console.error("Error al actualizar usuario:", error);
    }
  };

  const handleModalClick = (e) => {
    if (e.target.classList.contains("modal")) {
      closeModal();
    }
  };

  return (
    <div className="admin-user-container">
      <h1 className="admin-user-title">Usuarios</h1>
      <ul className="admin-user-list">
        {users.map((user) => (
          <li key={user.id} className="admin-user-item">
            <span className="admin-user-name">{user.username}</span>
            <div className="admin-user-actions">
              <svg fill="none" height="24" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" onClick={() => openModal(user)}>
                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
              </svg>
              <svg fill="none" height="24" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" onClick={() => deleteUser(user.id)}>
                <polyline points="3 6 5 6 21 6" />
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                <line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" />
              </svg>
            </div>
          </li>
        ))}
      </ul>
      {showModal && (
        <div className="modal" onClick={handleModalClick}>
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <svg
              height="24px"
              width="24px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="close-svg"
              onClick={closeModal} >
              <path d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z" />
            </svg>
            <h2>Editar Usuario</h2>
            <form onSubmit={handleUpdateUser}>
              <label htmlFor="username">Username:</label>
              <input type="text" id="username" name="username" value={formData.username} onChange={handleChange} />
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
              <label htmlFor="permissions">Permisos:</label>
              <select id="permissions" name="permissions" value={formData.permissions} onChange={handleChange}>
                {['admin', 'user', 'tester'].map(permission => (
                  <option key={permission} value={permission}>{permission}</option>
                ))}
              </select>
              <button type="submit">Actualizar</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminUser;
