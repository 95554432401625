import React from 'react';
import './promptButton.css';

const PromptButton = ({ onClick }) => {
  return (
    <button className='prompt-button' onClick={onClick}>
      Aceptar
    </button>
  );
};

export default PromptButton;
